import React, { useState } from 'react';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText,
  Toolbar, Typography, Button, Box, Collapse
} from '@mui/material';
import {
  Home, Hotel, Message, ExpandLess, ExpandMore, Add, List as ListIcon,
  ExitToApp
} from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';

const drawerWidth = 250;

const Navigation = () => {
  const [open, setOpen] = useState({
    safaris: false,
    hotels: false,
    messages: false
  });

  const handleClick = (menu) => {
    setOpen({ ...open, [menu]: !open[menu] });
  };

  const location = useLocation();
  const noSidebarPaths = ['/login', '/signup'];

  if (noSidebarPaths.includes(location.pathname)) {
    return null;
  }

  return (
    <div style={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#2C3539',
            color: 'white',
            padding:"10px"
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column', height: '100%' }}>
          {/* Typography added here */}
          <Typography variant="h5" align="center" sx={{ mt: 2, mb: 2, color: 'white' }}>
            Jokkajok Management App
          </Typography>
          <List style={{ flex: 1 }}>
            <ListItem button component={Link} to="/dashboard">
              <ListItemIcon sx={{ color: 'white' }}><Home /></ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>

            <ListItem button onClick={() => handleClick('safaris')}>
              <ListItemIcon sx={{ color: 'white' }}><Home /></ListItemIcon>
              <ListItemText primary="Safaris" />
              {open.safaris ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open.safaris} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/safaris">
                  <ListItemIcon><ListIcon /></ListItemIcon>
                  <ListItemText primary="View All" />
                </ListItem>
                <ListItem button component={Link} to="/createNewSafari">
                  <ListItemIcon><Add /></ListItemIcon>
                  <ListItemText primary="Add Safari" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={() => handleClick('hotels')}>
              <ListItemIcon sx={{ color: 'white' }}><Hotel /></ListItemIcon>
              <ListItemText primary="Hotels" />
              {open.hotels ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open.hotels} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/hotels">
                  <ListItemIcon><ListIcon /></ListItemIcon>
                  <ListItemText primary="View All" />
                </ListItem>
                <ListItem button component={Link} to="/createNewHotel">
                  <ListItemIcon><Add /></ListItemIcon>
                  <ListItemText primary="Add Hotel" />
                </ListItem>

                <ListItem button component={Link} to="/hotelAmenitiesList">
                  <ListItemIcon><ListIcon /></ListItemIcon>
                  <ListItemText primary="View Hotel Amenities" />
                </ListItem>
                <ListItem button component={Link} to="/createNewHotelAmenity">
                  <ListItemIcon><Add /></ListItemIcon>
                  <ListItemText primary="Add Hotel Amenity" />
                </ListItem>
                <ListItem button component={Link} to="/hotelTypeList">
                  <ListItemIcon><ListIcon /></ListItemIcon>
                  <ListItemText primary="View Hotel Types" />
                </ListItem>
                <ListItem button component={Link} to="/createNewHotelType">
                  <ListItemIcon><Add /></ListItemIcon>
                  <ListItemText primary="Add Hotel Type" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={() => handleClick('messages')}>
              <ListItemIcon sx={{ color: 'white' }}><Message /></ListItemIcon>
              <ListItemText primary="Messages" />
              {open.messages ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open.messages} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/messages">
                  <ListItemIcon><ListIcon /></ListItemIcon>
                  <ListItemText primary="View All" />
                </ListItem>
                <ListItem button component={Link} to="/createNewMessage">
                  <ListItemIcon><Add /></ListItemIcon>
                  <ListItemText primary="Add Message" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={() => handleClick('blogs')}>
              <ListItemIcon sx={{ color: 'white' }}><Message /></ListItemIcon>
              <ListItemText primary="Blogs" />
              {open.blogs ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open.blogs} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/blogList">
                  <ListItemIcon><ListIcon /></ListItemIcon>
                  <ListItemText primary="View All" />
                </ListItem>
                <ListItem button component={Link} to="/createNewBlog">
                  <ListItemIcon><Add /></ListItemIcon>
                  <ListItemText primary="Add Blog" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={() => handleClick('users')}>
              <ListItemIcon sx={{ color: 'white' }}><Message /></ListItemIcon>
              <ListItemText primary="Users" />
              {open.users ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open.users} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/userList">
                  <ListItemIcon><ListIcon /></ListItemIcon>
                  <ListItemText primary="View All" />
                </ListItem>
                <ListItem button component={Link} to="/createUser">
                  <ListItemIcon><Add /></ListItemIcon>
                  <ListItemText primary="Add User" />
                </ListItem>
              </List>
            </Collapse>
            
          </List>
          <Box sx={{ padding: '20px' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<ExitToApp />}
              component={Link}
              to="/logout"
            >
              Sign Out
            </Button>
          </Box>
        </div>
      </Drawer>
    </div>
  );
};

export default Navigation;
