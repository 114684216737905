import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Box, Grid, IconButton } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

const CreateNewSafari = () => {
  const [safariTitle, setSafariTitle] = useState('');
  const [location, setLocation] = useState('');
  const [safariDescription, setSafariDescription] = useState('');
  const [duration, setDuration] = useState('');
  const [itinerary, setItinerary] = useState([{ day: '', activities: [''] }]);
  const [images, setImages] = useState([]);

  const handleItineraryChange = (index, event) => {
    const values = [...itinerary];
    if (event.target.name === 'day') {
      values[index].day = event.target.value;
    } else {
      const activityIndex = parseInt(event.target.name.replace('activity', ''));
      values[index].activities[activityIndex] = event.target.value;
    }
    setItinerary(values);
  };

  const handleAddDay = () => {
    setItinerary([...itinerary, { day: '', activities: [''] }]);
  };

  const handleRemoveDay = (index) => {
    const values = [...itinerary];
    values.splice(index, 1);
    if (values.length === 0) {
      values.push({ day: '', activities: [''] }); 
    }
    setItinerary(values);
  };

  const handleAddActivity = (index) => {
    const values = [...itinerary];
    values[index].activities.push('');
    setItinerary(values);
  };

  const handleRemoveActivity = (dayIndex, activityIndex) => {
    const values = [...itinerary];
    values[dayIndex].activities.splice(activityIndex, 1);
    if (values[dayIndex].activities.length === 0) {
      values[dayIndex].activities.push(''); 
    }
    setItinerary(values);
  };

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('safariTitle', safariTitle);
    formData.append('safariDescription', safariDescription);
    formData.append('location', location);
    formData.append('duration', duration);
    formData.append('itinerary', JSON.stringify(itinerary)); // Convert itinerary to JSON string

    // Append images to form data
    images.forEach((image) => {
        formData.append('image', image);
    });

    try {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/safari`, formData);
        alert('Safari added successfully!');
        setSafariTitle('');
        setSafariDescription('');
        setLocation('');
        setDuration('');
        setItinerary([{ day: '', activities: [''] }]);
        setImages([]);
    } catch (error) {
        console.error('Error adding safari:', error);
        alert('Failed to add safari.');
    }
};

  

  return (
    <Container style={{ marginTop: '20px', display: 'space-evenly' }}>
      <Typography variant="h4" gutterBottom>
        Add a New Safari
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            label="Safari Title"
            variant="outlined"
            fullWidth
            value={safariTitle}
            onChange={(e) => setSafariTitle(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Safari Description"
            variant="outlined"
            fullWidth
            multiline
            rows={6} 
            value={safariDescription}
            onChange={(e) => setSafariDescription(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Location"
            variant="outlined"
            fullWidth
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Duration (days)"
            variant="outlined"
            fullWidth
            type="number"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          />
        </Box>

        {/* Image Upload Section */}
        <Typography variant="h6" gutterBottom>
          Upload Safari Images
        </Typography>
        <Box mb={2}>
          <input
            accept="image/*"
            type="file"
            multiple
            onChange={handleImageChange}
          />
        </Box>

        <Typography variant="h6">Itinerary</Typography>
        {itinerary.map((itineraryItem, index) => (
          <Box key={index} mb={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <IconButton onClick={handleAddDay} color="primary">
                  <AddCircleOutline />
                </IconButton>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Day"
                  variant="outlined"
                  fullWidth
                  name="day"
                  value={itineraryItem.day}
                  onChange={(event) => handleItineraryChange(index, event)}
                />
              </Grid>
              <Grid item>
                <IconButton onClick={() => handleRemoveDay(index)} color="secondary">
                  <RemoveCircleOutline />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                {itineraryItem.activities.map((activity, activityIndex) => (
                  <Grid container spacing={2} alignItems="center" key={activityIndex}>
                    <Grid item xs={9}>
                      <TextField
                        label={`Activity ${activityIndex + 1}`}
                        variant="outlined"
                        fullWidth
                        name={`activity${activityIndex}`}
                        value={activity}
                        onChange={(event) => handleItineraryChange(index, event)}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => handleAddActivity(index)} color="primary">
                        <AddCircleOutline />
                      </IconButton>
                      {activityIndex > 0 && (
                        <IconButton onClick={() => handleRemoveActivity(index, activityIndex)} color="secondary">
                          <RemoveCircleOutline />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        ))}

        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Add Safari
        </Button>
      </form>
    </Container>
  );
};

export default CreateNewSafari;
