import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../Sections/Footer";
import ProductPageHeader from "../Sections/ProductPageHeader";

export default function ProductPage() {
  const { id } = useParams(); // Get the hotel ID from the route params
  const navigate = useNavigate();
  const [hotel, setHotel] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchHotelDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/hotel/${id}`);
        setHotel(response.data);
      } catch (error) {
        console.error("Error fetching hotel details:", error);
      }
    };

    fetchHotelDetails();
  }, [id]);

  if (!hotel) {
    return <div>Loading...</div>; // Display a loading state while fetching hotel data
  }

  const handleEnquire = () => {
    navigate('/booking-form'); // Replace with the actual enquiry page path
  };

  return (
    <>
      <ProductPageHeader
        hotelName={hotel.name}
        hotelDescription={hotel.description}
        hotelImages={hotel.hotelImages.map(img => `${process.env.REACT_APP_API_BASE_URL}${img.hotelImageUrl}`)}
      />
      <Wrapper id="aboutPage">
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40 extraBold">{hotel.name}</h2>
                <p className="font12">Location: {hotel.location}</p>
                <p className="font12">{hotel.description}</p>
                <br />
                <h4 className="font15 semiBold">Amenities</h4>
                <ul className="font12">
                  {hotel.amenities.map((amenity) => (
                    <li key={amenity.amenityID}>{amenity.amenity}</li>
                  ))}
                </ul>
              </AddLeft>
              <AddRight>
                {/* You can add more content here if needed */}
              </AddRight>
            </Advertising>
          </div>
        </div>
      </Wrapper>
      <br />
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;

const EnquireButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const EnquireButton = styled.button`
  background-color: #2c3539;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: black;
  }
`;
