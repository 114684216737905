import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Typography, Box, Grid, Button, Paper, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { Home, Inbox, Receipt } from '@mui/icons-material';
import Navigation from './navigation';  // Adjusted path to reflect new structure
import dayjs from 'dayjs';  // Import dayjs for date formatting

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    safaris: 0,
    messages: 0,
    hotels: 0,
    bookings: 0,
  });

  const [messages, setMessages] = useState([]);
  const [safaris, setSafaris] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const safarisResponse = await axios.get(`${apiUrl}/api/v1/safari`);
        const messagesResponse = await axios.get(`${apiUrl}/api/v1/message`);
        const hotelsResponse = await axios.get(`${apiUrl}/api/v1/hotel`);
        const bookingsResponse = await axios.get(`${apiUrl}/api/v1/booking`);

        // Sort messages and bookings in descending order based on createdAt
        const sortedMessages = messagesResponse.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        const sortedBookings = bookingsResponse.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setSafaris(safarisResponse.data);
        setMessages(sortedMessages); // Set sorted messages
        setHotels(hotelsResponse.data);
        setBookings(sortedBookings); // Set sorted bookings

        setDashboardData({
          safaris: safarisResponse.data.length,
          messages: sortedMessages.length,
          hotels: hotelsResponse.data.length,
          bookings: sortedBookings.length,
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [apiUrl]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Navigation />
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginLeft: `-200px` }}>
        <Typography variant="h5" gutterBottom>Dashboard</Typography>
        <Grid container spacing={2}>
          {/* Safaris */}
          <Grid item xs={3}>
            <Paper elevation={3} sx={{ padding: '10px' }}>
              <Home fontSize="large" />
              <Typography variant="h6">Safaris</Typography>
              <Typography variant="h4">{dashboardData.safaris}</Typography>
              <Button component={Link} to="/safari-list" variant="outlined" sx={{
                width: '100px',
                marginBottom: '2px',
                backgroundColor: '#2C3539',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'black',
                  borderColor: 'white',
                }
              }}>View</Button>
              <Button component={Link} to="/createNewSafari" variant="outlined" color="primary" sx={{ width: '50px', left: '72px', color: '#2C3539', borderColor: 'black' }}>Add</Button>
            </Paper>
          </Grid>

          {/* Messages */}
          <Grid item xs={3}>
            <Paper elevation={3} sx={{ padding: '10px' }}>
              <Inbox fontSize="large" />
              <Typography variant="h6">Messages</Typography>
              <Typography variant="h4">{dashboardData.messages}</Typography>
              <Button component={Link} to="/messages" variant="outlined" sx={{
                width: '100px',
                marginBottom: '2px',
                backgroundColor: '#2C3539',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'black',
                  borderColor: 'white',
                }
              }}>View</Button>
              <Button component={Link} to="/createNewMessage" variant="outlined" color="primary" sx={{ width: '50px', left: '72px', color: '#2C3539', borderColor: 'black' }}>Add</Button>
            </Paper>
          </Grid>

          {/* Hotels */}
          <Grid item xs={3}>
            <Paper elevation={3} sx={{ padding: '10px' }}>
              <Home fontSize="large" />
              <Typography variant="h6">Hotels</Typography>
              <Typography variant="h4">{dashboardData.hotels}</Typography>
              <Button component={Link} to="/hotelList" variant="outlined" sx={{
                width: '100px',
                marginBottom: '2px',
                backgroundColor: '#2C3539',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'black',
                  borderColor: 'white',
                }
              }}>View</Button>
              <Button component={Link} to="/createNewHotel" variant="outlined" color="primary" sx={{ width: '50px', left: '72px', color: '#2C3539', borderColor: 'black' }}>Add</Button>
            </Paper>
          </Grid>

          {/* Bookings */}
          <Grid item xs={3}>
            <Paper elevation={3} sx={{ padding: '10px' }}>
              <Receipt fontSize="large" />
              <Typography variant="h6">Bookings</Typography>
              <Typography variant="h4">{dashboardData.bookings}</Typography>
              <Button component={Link} to="/bookingList" variant="outlined" sx={{
                width: '100px',
                marginBottom: '2px',
                backgroundColor: '#2C3539',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'black',
                  borderColor: 'white',
                }
              }}>View</Button>
              <Button component={Link} to="/createNewBooking" variant="outlined" color="primary" sx={{ width: '50px', left: '72px', color: '#2C3539', borderColor: 'black' }}>Add</Button>
            </Paper>
          </Grid>
        </Grid>

        {/* Recent Messages */}
        <Box sx={{ marginTop: '20px' }}>
          <Typography variant="h6" gutterBottom>Recent Messages</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sender Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.map((message) => (
                  <TableRow key={message.messageID}>
                    <TableCell>{message.fname + " " + message.lname}</TableCell>
                    <TableCell>{message.email}</TableCell>
                    <TableCell>{message.messageEnquiry}</TableCell>
                    <TableCell>{dayjs(message.createdAt).format('DD/MM/YYYY')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Recent Bookings */}
        <Box sx={{ marginTop: '20px' }}>
          <Typography variant="h6" gutterBottom>Recent Bookings</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Booking</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Budget</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Adults</TableCell>
                  <TableCell>Children</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings.map((booking) => (
                  <TableRow key={booking.bookingID}>
                    <TableCell>{booking.packageType}</TableCell>
                    <TableCell>{booking.fname + " " + booking.lname}</TableCell>
                    <TableCell>{booking.budget}</TableCell>
                    <TableCell>{booking.email}</TableCell>
                    <TableCell>{booking.phoneNumber}</TableCell>
                    <TableCell>{dayjs(booking.startDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{dayjs(booking.endDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{booking.noOfAdults}</TableCell>
                    <TableCell>{booking.noOfChildren}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
