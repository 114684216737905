import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField, Button, Container, Typography, Box, CircularProgress
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const EditBooking = () => {
  const { bookingID } = useParams();
  const navigate = useNavigate();
  const [booking, setBooking] = useState({
    packageType: '',
    startDate: null,
    endDate: null,
    noOfAdults: '',
    noOfChildren: '',
    fname: '',
    lname: '',
    phoneNumber: '',
    email: '',
    message: '',
    countryOfResidence: ''

  });
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/booking/${bookingID}`);
        const bookingData = response.data;
        setBooking({
          ...bookingData,
          startDate: dayjs(bookingData.startDate),
          endDate: dayjs(bookingData.endDate),
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching booking:', error);
        setLoading(false);
      }
    };

    fetchBooking();
  }, [bookingID, apiUrl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBooking({ ...booking, [name]: value });
  };

  const handleDateChange = (date, field) => {
    setBooking({ ...booking, [field]: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${apiUrl}/api/v1/booking/${bookingID}`, {
        ...booking,
        startDate: booking.startDate.toDate(),
        endDate: booking.endDate.toDate(),
      });
      alert('Booking updated successfully!');
      navigate('/bookingList');
    } catch (error) {
      console.error('Error updating booking:', error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Edit Booking
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            label="Package Type"
            name="packageType"
            variant="outlined"
            fullWidth
            value={booking.packageType}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={booking.startDate}
              onChange={(date) => handleDateChange(date, 'startDate')}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Box>
        <Box mb={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={booking.endDate}
              onChange={(date) => handleDateChange(date, 'endDate')}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Box>
        <Box mb={2}>
          <TextField
            label="Number of Adults"
            name="noOfAdults"
            variant="outlined"
            fullWidth
            type="number"
            value={booking.noOfAdults}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Number of Children"
            name="noOfChildren"
            variant="outlined"
            fullWidth
            type="number"
            value={booking.noOfChildren}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="First Name"
            name="fname"
            variant="outlined"
            fullWidth
            value={booking.fname}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Last Name"
            name="lname"
            variant="outlined"
            fullWidth
            value={booking.lname}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Country of Residence"
            name="countryOfResidence"
            variant="outlined"
            fullWidth
            value={booking.countryOfResidence}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Phone Number"
            name="phoneNumber"
            variant="outlined"
            fullWidth
            value={booking.phoneNumber}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Email"
            name="email"
            variant="outlined"
            fullWidth
            type="email"
            value={booking.email}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Message"
            name="message"
            variant="outlined"
            fullWidth
            value={booking.message}
            onChange={handleInputChange}
          />
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Save Changes
        </Button>
      </form>
    </Container>
  );
};

export default EditBooking;
