import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom"; 
import axios from "axios";

// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage2 from "../../assets/img/ourstory.jpeg";

export default function Projects() {
  const navigate = useNavigate();
  const [safaris, setSafaris] = useState([]);

  useEffect(() => {
    const fetchSafaris = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/safari`);
        setSafaris(response.data);
      } catch (error) {
        console.error("Error fetching safaris:", error);
      }
    };

    fetchSafaris();
  }, []);

  const handleReadMore = () => {
    navigate('/about'); 
  };

  const handleLoadMore = () => {
    navigate('/explore-kenya');
  }

  const handleViewDetails = (safariID) => {
    navigate(`/safari/${safariID}`);
  }

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Explore Kenya Safaris</h1>
          </HeaderInfo>
          <div className="row textCenter">
            {safaris.slice(0, 6).map((safari) => (
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" key={safari.safariID}>
                <ProjectBox
                  img={
                    safari.safariImages && safari.safariImages.length > 0
                      ? `${process.env.REACT_APP_API_BASE_URL}${safari.safariImages[0].safariImageUrl}`
                      : null // Add a fallback image if necessary
                  }
                  title={safari.safariTitle}
                  text={`Location: ${safari.location}, Duration: ${safari.duration} days`}
                  action={() => handleViewDetails(safari.safariID)}
                  imgStyle={{ width: '376px', height: '309px', objectFit: 'cover' }}
                />
              </div>
            ))}
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={handleLoadMore} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about Jokkajok Safaris</h4>
              <h2 className="font40 extraBold">Our Story</h2>
              <p className="font12">
                As a family-run business based in Kenya, we take pride in offering unique and unforgettable experiences that showcase the unparalleled richness of East and Central Africa.
                From the majestic savannahs of the Maasai Mara to the pristine shores of the Indian Ocean, Jokkajok Safaris specializes in crafting tailor-made adventures that immerse you in the wonders of nature.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Read more" action={handleReadMore} />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
