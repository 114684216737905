import React from 'react';
import { Box } from '@mui/material';
import Navigation from './navigation';

const AdminLayout = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Navigation />
      <Box component="main" sx={{ flexGrow: 1, p: 1, marginLeft: '0px' }}>
        {children}
      </Box>
    </Box>
  );
};

export default AdminLayout;
