import React from "react";
import { Helmet } from "react-helmet";
import { Routes, Route } from "react-router-dom";

// Screens
import Landing from "./screens/Landing.jsx";
import About from "./components/Pages/About.jsx";
import ExploreKenya from "./components/Pages/ExploreKenya.jsx";
import ProductPage from "./components/Pages/ProductPage.jsx";
import Dashboard from "./components/Admin/dashboard.js";
import CreateNewSafari from "./components/Admin/createNewSafari.js";

// Layouts
import AdminLayout from "./components/Admin/AdminLayout";
import SafariList from "./components/Admin/SafariList.js";
import EditSafari from "./components/Admin/EditSafari.js";
import CreateNewHotel from "./components/Admin/createNewHotel.js";
import CreateNewHotelAmenity from "./components/Admin/createNewHotelAmenity.js";
import CreateNewHotelType from "./components/Admin/createNewHotelType.js";
import HotelAmenitiesList from "./components/Admin/hotelAmenities.js";
import HotelTypesList from "./components/Admin/hotelTypes.js";
import ViewHotels from "./components/Admin/HotelList.js";
import EditHotel from "./components/Admin/EditHotel.js";
import AddBooking from "./components/Admin/createNewBooking.js";
import BookingList from "./components/Admin/BookingsList.js";
import EditBooking from "./components/Admin/EditBooking.js";
import Hotels from "./components/Pages/Hotels.jsx";
import BookingForm from "./components/Pages/Booking.jsx";
import AddBlog from "./components/Admin/createNewBlog.js";
import BlogList from "./components/Admin/BlogList.js";
import EditBlogPage from "./components/Admin/EditBlog.js";
import AllBlogs from "./components/Pages/AllBlogs.jsx";
import BlogDetails from "./components/Pages/BlogDetails.jsx";
import SafariPage from "./components/Pages/SafariDetails.jsx";
import CreateUser from "./components/Admin/CreateUser.js";
import UserList from "./components/Admin/UsersList.js";
import LoginForm from "./components/Admin/LoginForm.js";
import RegisterForm from "./components/Admin/CreateUser.js";
import ProtectedRoute from "./components/Admin/ProtectedRoute.js";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/explore-kenya" element={<ExploreKenya />} />
        <Route path="/product-page/:id" element={<ProductPage />} />
        <Route path="/hotels" element={<Hotels />} />
        <Route path="/booking-form" element={<BookingForm />} />
        <Route path="/all-blogs" element={<AllBlogs />} />
        <Route path="/blog/:blogId" element={<BlogDetails />} />
        <Route path="/safari/:safariID" element={<SafariPage />} />

        {/* Auth Routes */}
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />

        {/* Protected Admin Routes */}
        <Route 
          path="/dashboard" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <Dashboard />
              </AdminLayout>
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/createNewSafari" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <CreateNewSafari />
              </AdminLayout>
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/safari-list" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <SafariList />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 
        <Route 
          path="/edit-safari/:safariID" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <EditSafari />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/createNewHotel" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <CreateNewHotel />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/createNewHotelAmenity" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <CreateNewHotelAmenity />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/createNewHotelType" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <CreateNewHotelType />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/hotelAmenitiesList" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <HotelAmenitiesList />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/hotelTypeList" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <HotelTypesList />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/hotelList" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <ViewHotels />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/editHotel/:id" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <EditHotel />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/createNewBooking" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <AddBooking />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/bookingList" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <BookingList />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/editBooking/:bookingID" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <EditBooking />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/createNewBlog" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <AddBlog />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/blogList" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <BlogList />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/editBlog/:blogId" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <EditBlogPage />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/createUser" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <CreateUser />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 

        <Route 
          path="/userList" 
          element={
            <ProtectedRoute>
              <AdminLayout>
                <UserList />
              </AdminLayout>
            </ProtectedRoute>
          } 
        /> 
      </Routes>
    </>
  );
}
