import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Box } from '@mui/material';

const CreateNewHotelAmenity = () => {
    const [amenity, setAmenity] = useState('');
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    const handleInputChange = (e) => {
        setAmenity(e.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/api/v1/hotel/amenities`, { amenity });
            alert('Amenity added successfully!');
            setAmenity(''); // Reset the input field
        } catch (error) {
            console.error('Error adding amenity:', error);
            alert('Failed to add amenity.');
        }
    };

    return (
        <Container style={{ marginTop: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Add New Hotel Amenity
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box mb={2}>
                    <TextField
                        label="Amenity"
                        variant="outlined"
                        fullWidth
                        value={amenity}
                        onChange={handleInputChange}
                    />
                </Box>
                <Button type="submit" variant="contained" color="primary">
                    Add Amenity
                </Button>
            </form>
        </Container>
    );
};

export default CreateNewHotelAmenity;
