import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Container, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const ViewHotels = () => {
  const [hotels, setHotels] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/hotel`);
        setHotels(response.data);
      } catch (error) {
        console.error('Error fetching hotels:', error);
      }
    };

    fetchHotels();
  }, [apiUrl]);

  const handleEdit = (hotelID) => {
    navigate(`/editHotel/${hotelID}`);
  };

  const handleDelete = async (hotelID) => {
    try {
      await axios.delete(`${apiUrl}/api/v1/hotel/${hotelID}`);
      setHotels(hotels.filter((hotel) => hotel.id !== hotelID));
    } catch (error) {
      console.error('Error deleting hotel:', error);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'location', headerName: 'Location', width: 150 },
    { field: 'description', headerName: 'Description', width: 250 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row.id)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)} color="secondary">
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Container style={{ marginTop: '20px' }}>
      <h2>View Hotels</h2>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={hotels}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '20px' }}
        onClick={() => navigate('/createNewHotel')}
      >
        Add New Hotel
      </Button>
    </Container>
  );
};

export default ViewHotels;
