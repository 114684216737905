import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import { ArrowBack } from '@mui/icons-material'; // Importing MUI icon

export default function BlogDetails() {
  const { blogId } = useParams();
  const [blog, setBlog] = useState(null);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/blog/${blogId}`);
        setBlog(response.data);
      } catch (error) {
        console.error("Error fetching blog details:", error);
      }
    };

    fetchBlog();
  }, [blogId]);

  if (!blog) {
    return <p>Loading...</p>;
  }

  const formattedDate = new Date(blog.createdAt).toLocaleDateString();



  return (
    <BlogWrapper>
       <BackButtonWrapper onClick={handleBack}>
        <ArrowBack /> {/* Back arrow icon */}
        <span>   Back</span>
       </BackButtonWrapper>
      <div className="container">
        <Header>
          <h1 className="font40 extraBold">{blog.blogTitle}</h1>
          <BlogInfo>
            <Author>{blog.author}</Author>
            <DateLayout>{formattedDate}</DateLayout>
            <ReadTime>• {Math.ceil(blog.blogContent.split(" ").length / 200)} min read</ReadTime>
          </BlogInfo>
        </Header>

        <BlogContent>
          {blog.blogImage && <BlogImage src={`${process.env.REACT_APP_API_BASE_URL}${blog.blogImage}`} alt={blog.blogTitle} />}
          <p>{blog.blogContent}</p>
        </BlogContent>

        <Tags>
          {blog.blogTags.map((tag, index) => (
            <Tag key={index}>#{tag.tag}</Tag>
          ))}
        </Tags>
      </div>
    </BlogWrapper>
  );
}

const BlogWrapper = styled.div`
  padding: 50px 0;
  background: #f9f9f9;
`;

const Header = styled.div`
  margin-bottom: 30px;
`;

const BlogInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #999;
`;

const Author = styled.div`
  font-weight: bold;
  margin-right: 10px;
`;

const DateLayout = styled.div`
  margin-right: 10px;
`;

const ReadTime = styled.div`
  margin-left: auto;
`;

const BlogContent = styled.div`
  margin-top: 30px;
  font-size: 18px;
  line-height: 1.6;
  color: #333;
`;

const BlogImage = styled.img`
  width: 80%;
  height: auto;
  margin-bottom: 30px;
  border-radius: 8px;
`;

const Tags = styled.div`
  margin-top: 40px;
`;

const Tag = styled.span`
  display: inline-block;
  background-color: #eee;
  color: #333;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 14px;
`;
const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #2c3539;
  font-weight: bold;
  padding: 10px;
  &:hover {
    color: black;
  }
`;