import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Button, IconButton
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';

const BookingList = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/booking`);
        setBookings(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching bookings:', error);
        setLoading(false);
      }
    };

    fetchBookings();
  }, [apiUrl]);

  const handleDelete = async (bookingID) => {
    try {
      await axios.delete(`${apiUrl}/api/v1/booking/${bookingID}`);
      setBookings(bookings.filter((booking) => booking.bookingID !== bookingID));
    } catch (error) {
      console.error('Error deleting booking:', error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Booking List
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/createNewBooking" sx={{ marginBottom: '20px' }}>
        Add New Booking
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Package Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Residency</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Adults</TableCell>
              <TableCell>Children</TableCell>
              <TableCell>Budget</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookings.map((booking) => (
              <TableRow key={booking.bookingID}>
                <TableCell>{booking.packageType}</TableCell>
                <TableCell>{booking.fname + " " + booking.lname}</TableCell>
                <TableCell>{booking.countryOfResidence}</TableCell>
                <TableCell>{booking.email}</TableCell>
                <TableCell>{booking.phoneNumber}</TableCell>
                <TableCell>{booking.noOfAdults}</TableCell>
                <TableCell>{booking.noOfChildren}</TableCell>
                <TableCell>{booking.budget}</TableCell>
                <TableCell>{new Date(booking.startDate).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(booking.endDate).toLocaleDateString()}</TableCell>
                      <TableCell>
                  <IconButton component={Link} to={`/editBooking/${booking.bookingID}`}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(booking.bookingID)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BookingList;
