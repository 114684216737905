import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField, Button, Container, Typography, Box, IconButton
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

const EditSafari = () => {
  const { safariID } = useParams(); 
  const [safari, setSafari] = useState({
    safariTitle: '',
    safariDescription: '',
    location: '',
    duration: '',
    safariItinerary: [],
    safariImages: []
  });
  const [newImageFiles, setNewImageFiles] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSafari = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/safari/${safariID}`);
        setSafari(response.data);
      } catch (error) {
        console.error('Error fetching safari:', error);
      }
    };

    fetchSafari();
  }, [safariID, apiUrl]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const safariItinerary = safari.safariItinerary.map(it => ({
      day: it.day,
      activities: it.activities 
    }));

    const formData = new FormData();
    formData.append('safariTitle', safari.safariTitle);
    formData.append('safariDescription', safari.safariDescription);
    formData.append('location', safari.location);
    formData.append('duration', safari.duration);
    formData.append('itinerary', JSON.stringify(safariItinerary));
    
    newImageFiles.forEach(file => formData.append('image', file));

    try {
      await axios.put(`${apiUrl}/api/v1/safari/${safariID}`, formData);
      navigate('/safari-list');
    } catch (error) {
      console.error('Error updating safari:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSafari({ ...safari, [name]: value });
  };

  const handleItineraryChange = (index, field, value, activityIndex = null) => {
    const newItinerary = [...safari.safariItinerary];
    
    if (field === 'activities' && activityIndex !== null) {
      newItinerary[index][field][activityIndex] = value;
    } else {
      newItinerary[index][field] = value;
    }
    
    setSafari({ ...safari, safariItinerary: newItinerary });
  };

  const handleAddItinerary = () => {
    setSafari({
      ...safari,
      safariItinerary: [...safari.safariItinerary, { day: '', activities: [''] }]
    });
  };

  const handleRemoveItinerary = (index) => {
    const updatedItinerary = safari.safariItinerary.filter((_, idx) => idx !== index);
    setSafari({ ...safari, safariItinerary: updatedItinerary });
  };

  const handleAddActivity = (index) => {
    const newItinerary = [...safari.safariItinerary];
    newItinerary[index].activities.push('');
    setSafari({ ...safari, safariItinerary: newItinerary });
  };

  const handleRemoveActivity = (index, activityIndex) => {
    const newItinerary = [...safari.safariItinerary];
    newItinerary[index].activities = newItinerary[index].activities.filter((_, idx) => idx !== activityIndex);
    setSafari({ ...safari, safariItinerary: newItinerary });
  };

  const handleFileChange = (e) => {
    setNewImageFiles([...e.target.files]);
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Edit Safari
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            label="Safari Title"
            name="safariTitle"
            variant="outlined"
            fullWidth
            value={safari.safariTitle}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Safari Description"
            name="safariDescription"
            variant="outlined"
            fullWidth
            value={safari.safariDescription}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Location"
            name="location"
            variant="outlined"
            fullWidth
            value={safari.location}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Duration"
            name="duration"
            variant="outlined"
            fullWidth
            type="number"
            value={safari.duration}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Itinerary</Typography>
          {safari.safariItinerary.map((safariItinerary, index) => (
            <Box key={index} mb={2}>
              <Box display="flex" alignItems="center" mb={1}>
                <TextField
                  label="Day"
                  variant="outlined"
                  style={{ marginRight: '10px' }}
                  value={safariItinerary.day}
                  onChange={(e) => handleItineraryChange(index, 'day', e.target.value)}
                />
                <IconButton onClick={() => handleRemoveItinerary(index)}>
                  <RemoveCircleOutline color="secondary" />
                </IconButton>
              </Box>
              {safariItinerary.activities.map((activity, activityIndex) => (
                <Box key={activityIndex} display="flex" alignItems="center" mb={1}>
                  <TextField
                    label={`Activity ${activityIndex + 1}`}
                    variant="outlined"
                    fullWidth
                    value={activity}
                    onChange={(e) => handleItineraryChange(index, 'activities', e.target.value, activityIndex)}
                  />
                  <IconButton onClick={() => handleRemoveActivity(index, activityIndex)}>
                    <RemoveCircleOutline color="secondary" />
                  </IconButton>
                </Box>
              ))}
              <Button
                variant="outlined"
                startIcon={<AddCircleOutline />}
                onClick={() => handleAddActivity(index)}
              >
                Add Activity
              </Button>
            </Box>
          ))}
          <Button
            variant="outlined"
            startIcon={<AddCircleOutline />}
            onClick={handleAddItinerary}
          >
            Add Day
          </Button>
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Upload New Images</Typography>
          <input type="file" multiple onChange={handleFileChange} />
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Save Changes
        </Button>
      </form>
    </Container>
  );
};

export default EditSafari;
