import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Input,
  IconButton,
  Chip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const CreateNewBlog = () => {
  const [blog, setBlog] = useState({
    blogTitle: '',
    blogContent: '',
    author: '',
    blogTags: [], // This will be an array of strings
    blogImage: ''
  });

  const [imageFile, setImageFile] = useState(null); // To store the selected image file
  const [tagInput, setTagInput] = useState(''); // To handle the input of new tags

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlog({ ...blog, [name]: value });
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleAddTag = () => {
    if (tagInput.trim() !== '') {
      setBlog({ ...blog, blogTags: [...blog.blogTags, tagInput.trim()] });
      setTagInput(''); // Clear the input field after adding a tag
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setBlog({ ...blog, blogTags: blog.blogTags.filter(tag => tag !== tagToDelete) });
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]); // Store the selected image file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('blogTitle', blog.blogTitle);
    formData.append('blogContent', blog.blogContent);
    formData.append('author', blog.author);
    formData.append('tags', blog.blogTags); // Send the array of tags

    if (imageFile) {
      formData.append('blogImage', imageFile);
    }

    try {
      // Upload the blog data to the server
      await axios.post(`${apiUrl}/api/v1/blog`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Blog created successfully!');

      // Reset form after submission
      setBlog({
        blogTitle: '',
        blogContent: '',
        author: '',
        blogTags: [],
        blogImage: ''
      });
      setImageFile(null); // Clear the selected image file
    } catch (error) {
      console.error('Error creating blog:', error);
      alert('There was an error creating your blog. Please try again.');
    }
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Create New Blog
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            label="Blog Title"
            name="blogTitle"
            variant="outlined"
            fullWidth
            value={blog.blogTitle}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Content"
            name="blogContent"
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            value={blog.blogContent}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Author"
            name="author"
            variant="outlined"
            fullWidth
            value={blog.author}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Tags</Typography>
          <Box display="flex" alignItems="center">
            <TextField
              label="Add Tag"
              variant="outlined"
              value={tagInput}
              onChange={handleTagInputChange}
            />
            <IconButton color="primary" onClick={handleAddTag}>
              <AddIcon />
            </IconButton>
          </Box>
          <Box mt={2}>
            {blog.blogTags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleDeleteTag(tag)}
                deleteIcon={<CloseIcon />}
                style={{ marginRight: '8px', marginBottom: '8px' }}
              />
            ))}
          </Box>
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Upload Blog Image</Typography>
          <Input
            type="file"
            onChange={handleImageChange}
          />
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Create Blog
        </Button>
      </form>
    </Container>
  );
};

export default CreateNewBlog;
