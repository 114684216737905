import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField, Button, Container, Typography, Box, FormGroup, FormControlLabel, Checkbox
} from '@mui/material';

const CreateNewHotel = () => {
  const [hotel, setHotel] = useState({
    name: '',
    location: '',
    description: '',
    selectedAmenities: [],
    selectedHotelTypes: [],
    hotelImages: []
  });

  const [amenities, setAmenities] = useState([]);
  const [hotelTypes, setHotelTypes] = useState([]);

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchAmenitiesAndTypes = async () => {
      try {
        const amenitiesResponse = await axios.get(`${apiUrl}/api/v1/hotel/amenities`);
        const hotelTypesResponse = await axios.get(`${apiUrl}/api/v1/hotel/hotelTypes`);
        setAmenities(amenitiesResponse.data);
        setHotelTypes(hotelTypesResponse.data);
      } catch (error) {
        console.error('Error fetching amenities or hotel types:', error);
      }
    };

    fetchAmenitiesAndTypes();
  }, [apiUrl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHotel({ ...hotel, [name]: value });
  };

  const handleAmenityChange = (e) => {
    const { value, checked } = e.target;
    const updatedAmenities = checked
      ? [...hotel.selectedAmenities, parseInt(value)]
      : hotel.selectedAmenities.filter((amenityID) => amenityID !== parseInt(value));
    setHotel({ ...hotel, selectedAmenities: updatedAmenities });
  };

  const handleHotelTypeChange = (e) => {
    const { value, checked } = e.target;
    const updatedHotelTypes = checked
      ? [...hotel.selectedHotelTypes, parseInt(value)]
      : hotel.selectedHotelTypes.filter((hotelTypeID) => hotelTypeID !== parseInt(value));
    setHotel({ ...hotel, selectedHotelTypes: updatedHotelTypes });
  };

  const handleFileChange = (e) => {
    setHotel({ ...hotel, hotelImages: [...e.target.files] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', hotel.name);
    formData.append('location', hotel.location);
    formData.append('description', hotel.description);
    formData.append('amenities', hotel.selectedAmenities);
    formData.append('hotelTypes', hotel.selectedHotelTypes);

    hotel.hotelImages.forEach((image) => {
      formData.append('hotelImages', image);
    });

    try {
      await axios.post(`${apiUrl}/api/v1/hotel`, formData);
      alert('Hotel created successfully!');
      // Reset form after submission
      setHotel({
        name: '',
        location: '',
        description: '',
        selectedAmenities: [],
        selectedHotelTypes: [],
        hotelImages: []
      });
    } catch (error) {
      console.error('Error creating hotel:', error);
    }
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Create New Hotel
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            label="Hotel Name"
            name="name"
            variant="outlined"
            fullWidth
            value={hotel.name}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Location"
            name="location"
            variant="outlined"
            fullWidth
            value={hotel.location}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Description"
            name="description"
            variant="outlined"
            fullWidth
            multiline
            rows={6} 
            value={hotel.description}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Select Amenities</Typography>
          <FormGroup>
            {amenities.map((amenity) => (
              <FormControlLabel
                key={amenity.amenityID}
                control={
                  <Checkbox
                    value={amenity.amenityID}
                    checked={hotel.selectedAmenities.includes(amenity.amenityID)}
                    onChange={handleAmenityChange}
                  />
                }
                label={amenity.amenity}
              />
            ))}
          </FormGroup>
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Select Hotel Types</Typography>
          <FormGroup>
            {hotelTypes.map((hotelType) => (
              <FormControlLabel
                key={hotelType.hotelTypeID}
                control={
                  <Checkbox
                    value={hotelType.hotelTypeID}
                    checked={hotel.selectedHotelTypes.includes(hotelType.hotelTypeID)}
                    onChange={handleHotelTypeChange}
                  />
                }
                label={hotelType.hotelTypeName}
              />
            ))}
          </FormGroup>
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Upload Hotel Images</Typography>
          <input type="file" multiple onChange={handleFileChange} />
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Create Hotel
        </Button>
      </form>
    </Container>
  );
};

export default CreateNewHotel;
