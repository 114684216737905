import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const HotelTypesList = () => {
  const [hotelTypes, setHotelTypes] = useState([]);

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchHotelTypes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/hotel/hotelTypes`);
        setHotelTypes(response.data);
      } catch (error) {
        console.error('Error fetching hotel types:', error);
      }
    };

    fetchHotelTypes();
  }, [apiUrl]);

  const handleEdit = (hotelTypeID) => {
    // Implement your edit logic here
    console.log(`Edit Hotel Type with ID: ${hotelTypeID}`);
  };

  const handleDelete = async (hotelTypeID) => {
    try {
      await axios.delete(`${apiUrl}/api/v1/hotel/hotelTypes/${hotelTypeID}`);
      setHotelTypes(hotelTypes.filter((hotelType) => hotelType.hotelTypeID !== hotelTypeID));
    } catch (error) {
      console.error('Error deleting hotel type:', error);
    }
  };

  const columns = [
    { field: 'hotelTypeID', headerName: 'Type ID', width: 100 },
    { field: 'hotelTypeName', headerName: 'Type Name', width: 300 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleEdit(params.row.hotelTypeID)}
            aria-label="edit"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleDelete(params.row.hotelTypeID)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
      <h2>Hotel Types</h2>
      <DataGrid
        rows={hotelTypes}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        getRowId={(row) => row.hotelTypeID}
      />
    </div>
  );
};

export default HotelTypesList;
