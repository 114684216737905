import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField, Button, Container, Typography, Box, FormGroup, FormControlLabel, Checkbox, IconButton
} from '@mui/material';

const EditHotel = () => {
  const { id } = useParams(); // Get hotel ID from URL parameters
  const [hotel, setHotel] = useState({
    name: '',
    location: '',
    description: '',
    selectedAmenities: [],
    selectedHotelTypes: [],
    hotelImages: []
  });
  const [amenities, setAmenities] = useState([]);
  const [hotelTypes, setHotelTypes] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchHotelData = async () => {
      try {
        const hotelResponse = await axios.get(`${apiUrl}/api/v1/hotel/${id}`);
        const amenitiesResponse = await axios.get(`${apiUrl}/api/v1/hotel/amenities`);
        const hotelTypesResponse = await axios.get(`${apiUrl}/api/v1/hotel/hotelTypes`);

        setHotel({
          ...hotelResponse.data,
          selectedAmenities: hotelResponse.data.amenities.map(a => a.amenityID),
          selectedHotelTypes: hotelResponse.data.hotelType.map(ht => ht.hotelTypeID),
        });
        setAmenities(amenitiesResponse.data);
        setHotelTypes(hotelTypesResponse.data);
      } catch (error) {
        console.error('Error fetching hotel data:', error);
      }
    };

    fetchHotelData();
  }, [id, apiUrl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHotel({ ...hotel, [name]: value });
  };

  const handleAmenityChange = (e) => {
    const { value, checked } = e.target;
    const updatedAmenities = checked
      ? [...hotel.selectedAmenities, Number(value)]
      : hotel.selectedAmenities.filter((amenityID) => amenityID !== Number(value));
    setHotel({ ...hotel, selectedAmenities: updatedAmenities });
  };

  const handleHotelTypeChange = (e) => {
    const { value, checked } = e.target;
    const updatedHotelTypes = checked
      ? [...hotel.selectedHotelTypes, Number(value)]
      : hotel.selectedHotelTypes.filter((hotelTypeID) => hotelTypeID !== Number(value));
    setHotel({ ...hotel, selectedHotelTypes: updatedHotelTypes });
  };

  const handleFileChange = (e) => {
    setNewImages([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', hotel.name);
    formData.append('location', hotel.location);
    formData.append('description', hotel.description);
    formData.append('amenities', hotel.selectedAmenities.join(','));
    formData.append('hotelTypes', hotel.selectedHotelTypes.join(','));

    newImages.forEach((image) => {
      formData.append('hotelImages', image);
    });

    try {
      await axios.put(`${apiUrl}/api/v1/hotel/${id}`, formData);
      alert('Hotel updated successfully!');
      navigate('/hotelList'); // 
    } catch (error) {
      console.error('Error updating hotel:', error);
    }
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Edit Hotel
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            label="Hotel Name"
            name="name"
            variant="outlined"
            fullWidth
            value={hotel.name}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Location"
            name="location"
            variant="outlined"
            fullWidth
            value={hotel.location}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Description"
            name="description"
            variant="outlined"
            fullWidth
            value={hotel.description}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Select Amenities</Typography>
          <FormGroup>
            {amenities.map((amenity) => (
              <FormControlLabel
                key={amenity.amenityID}
                control={
                  <Checkbox
                    value={amenity.amenityID}
                    checked={hotel.selectedAmenities.includes(amenity.amenityID)}
                    onChange={handleAmenityChange}
                  />
                }
                label={amenity.amenity}
              />
            ))}
          </FormGroup>
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Select Hotel Types</Typography>
          <FormGroup>
            {hotelTypes.map((hotelType) => (
              <FormControlLabel
                key={hotelType.hotelTypeID}
                control={
                  <Checkbox
                    value={hotelType.hotelTypeID}
                    checked={hotel.selectedHotelTypes.includes(hotelType.hotelTypeID)}
                    onChange={handleHotelTypeChange}
                  />
                }
                label={hotelType.hotelTypeName}
              />
            ))}
          </FormGroup>
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Upload New Hotel Images</Typography>
          <input type="file" multiple onChange={handleFileChange} />
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Update Hotel
        </Button>
      </form>
    </Container>
  );
};

export default EditHotel;
