import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import BookingHeader from '../Sections/BookingHeader';
import Footer from '../Sections/Footer';

export default function BookingForm() {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    phoneNumber: '',
    country: 'Kenya',
    countryOfResidence: '',
    packageType: '',
    startDate: '',
    endDate: '',
    noOfAdults: '',
    noOfChildren: '',
    message: '',
    budget: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      const locations = formData.locations.includes(value)
        ? formData.locations.filter((loc) => loc !== value)
        : [...formData.locations, value];
      setFormData({ ...formData, locations });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/booking`, formData);
      alert('Booking submitted successfully!');
    } catch (error) {
      console.error('Error submitting booking:', error);
      alert('There was an error submitting your booking. Please try again.');
    }
  };

  return (
    <>
      <BookingHeader />
      <Wrapper>
        <form onSubmit={handleSubmit}>

          <Fieldset>
            <Legend>Name <Required>(Required)</Required></Legend>
            <InputWrapper>
              <input
                type="text"
                name="fname"
                placeholder="First Name"
                required
                value={formData.fname}
                onChange={handleChange}
              />
              <input
                type="text"
                name="lname"
                placeholder="Last Name"
                required
                value={formData.lname}
                onChange={handleChange}
              />
            </InputWrapper>
          </Fieldset>

          <Fieldset>
            <Legend>Email <Required>(Required)</Required></Legend>
            <InputWrapper>
              <input
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </InputWrapper>
          </Fieldset>

          <Fieldset>
            <Legend>Phone <Required>(Required)</Required></Legend>
            <InputWrapper>
              <select name="country" value={formData.country} onChange={handleChange}>
                <option value="Kenya">Kenya</option>
                {/* Add more country options as needed */}
              </select>
              <input
                type="text"
                name="phoneNumber"
                placeholder="+254"
                required
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </InputWrapper>
          </Fieldset>

          <Fieldset>
            <Legend>Country of Residence <Required>(Required)</Required></Legend>
            <InputWrapper>
              <input
                type="text"
                name="countryOfResidence"
                required
                value={formData.countryOfResidence}
                onChange={handleChange}
              />
            </InputWrapper>
          </Fieldset>

          <Fieldset>
            <Legend>Package Name <Required>(Required)</Required></Legend>
            <InputWrapper>
              <input
                type="text"
                name="packageType"
                placeholder="Package Name"
                required
                value={formData.packageType}
                onChange={handleChange}
              />
            </InputWrapper>
          </Fieldset>

          <Fieldset>
            <Legend>Budget <Required>(USD)</Required></Legend>
            <InputWrapper>
              <input
                name="budget"
                placeholder="Your Budget"
                required
                value={formData.budget}
                onChange={handleChange}
              />
            </InputWrapper>
          </Fieldset>

          <Fieldset>
            <Legend>Start Date <Required>(Required)</Required></Legend>
            <InputWrapper>
              <input
                type="date"
                name="startDate"
                required
                value={formData.startDate}
                onChange={handleChange}
              />
            </InputWrapper>
          </Fieldset>

          <Fieldset>
            <Legend>End Date <Required>(Required)</Required></Legend>
            <InputWrapper>
              <input
                type="date"
                name="endDate"
                required
                value={formData.endDate}
                onChange={handleChange}
              />
            </InputWrapper>
          </Fieldset>

          <Fieldset>
            <Legend>Number of Adults <Required>(Required)</Required></Legend>
            <InputWrapper>
              <input
                type="number"
                name="noOfAdults"
                required
                value={formData.noOfAdults}
                onChange={handleChange}
              />
            </InputWrapper>
          </Fieldset>

          <Fieldset>
            <Legend>Number of Children</Legend>
            <InputWrapper>
              <input
                type="number"
                name="noOfChildren"
                value={formData.noOfChildren}
                onChange={handleChange}
              />
            </InputWrapper>
          </Fieldset>

          <Fieldset>
            <Legend>Message</Legend>
            <InputWrapper>
              <input
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
            </InputWrapper>
          </Fieldset>

          <Button type="submit">Submit Booking</Button>
        </form>
      </Wrapper>
      <br />
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  background: #f3f3f3;
  border-radius: 10px;
  max-width: 1000px; /* Increased width for a wider form */
  margin: auto;
`;

const Fieldset = styled.fieldset`
  border: none;
  margin-bottom: 20px;
  padding: 0;
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  input, select {
    flex: 1;
    padding: 12px; /* Slightly larger padding for wider input fields */
    border: 1px solid #ccc;
    border-radius: 5px;
    color: black;
  }
`;

const Legend = styled.legend`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
`;

const Required = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  color: #888;
`;

const Button = styled.button`
  padding: 15px;
  background-color: #2c3539;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: black;
  }
`;
