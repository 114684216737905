import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const HotelAmenitiesList = () => {
  const [amenities, setAmenities] = useState([]);

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchAmenities = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/hotel/amenities`);
        setAmenities(response.data);
      } catch (error) {
        console.error('Error fetching amenities:', error);
      }
    };

    fetchAmenities();
  }, [apiUrl]);

  const handleEdit = (amenityID) => {
    // Implement your edit logic here
    console.log(`Edit Amenity with ID: ${amenityID}`);
  };

  const handleDelete = async (amenityID) => {
    try {
      await axios.delete(`${apiUrl}/api/v1/hotel/amenities/${amenityID}`);
      setAmenities(amenities.filter((amenity) => amenity.amenityID !== amenityID));
    } catch (error) {
      console.error('Error deleting amenity:', error);
    }
  };

  const columns = [
    { field: 'amenityID', headerName: 'Amenity ID', width: 100 },
    { field: 'amenity', headerName: 'Amenity Name', width: 300 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleEdit(params.row.amenityID)}
            aria-label="edit"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleDelete(params.row.amenityID)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
      <h2>Hotel Amenities</h2>
      <DataGrid
        rows={amenities}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        getRowId={(row) => row.amenityID}
      />
    </div>
  );
};

export default HotelAmenitiesList;
