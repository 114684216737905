import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Components
import ProjectBox from "../Elements/ProjectBox";
import Footer from "../Sections/Footer";
import BlogDetailsHeader from "../Sections/BlogDetailsHeader";

export default function AllBlogs() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/blog`);
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  const handleViewBlog = (blogID) => {
    navigate(`/blog/${blogID}`);
  };

  return (
    <>
    <BlogDetailsHeader/>
      <Wrapper id="projects">
        <div className="whiteBg">
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">All Blog Posts</h1>
            </HeaderInfo>
            <div className="row textCenter">
              {blogs.map((blog) => (
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" key={blog.blogId}>
                  <ProjectBox
                    img={
                      blog.blogImage
                        ? `${process.env.REACT_APP_API_BASE_URL}${blog.blogImage}`
                        : null // fallback image can be added here
                    }
                    title={blog.blogTitle}
                    text={`${blog.blogContent.substring(0, 100)}...`} // Shortened content preview
                    action={() => handleViewBlog(blog.blogId)}
                    imgStyle={{ width: '376px', height: '309px', objectFit: 'cover', objectPosition: 'center' }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Wrapper>
      <br />
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
