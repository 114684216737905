import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from '@mui/icons-material'; // Importing MUI icon

export default function ProductPageHeader({ hotelName, hotelDescription, hotelImages }) {

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleEnquire = () => {
    navigate('/booking-form'); // Replace with the actual enquiry page path
  };

  return (
    <Wrapper id="services">
      <BackButtonWrapper onClick={handleBack}>
        <ArrowBack /> {/* Back arrow icon */}
        <span>Back</span>
      </BackButtonWrapper>
      <div className="whiteBg" style={{ padding: "50px 0", marginTop: "-10px" }}>
        <div className="lightBg" style={{ marginTop: "10px" }}>
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40 extraBold">{hotelName}</h2>
                <p className="font12">
                  {hotelDescription}
                </p>
                <EnquireButtonWrapper>
                  <EnquireButton onClick={handleEnquire}>Enquire</EnquireButton>
                </EnquireButtonWrapper>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    {hotelImages && hotelImages.length > 0 && (
                      <AddImgWrapp1 className="flexCenter" title="Hotel Image 1">
                        <img src={hotelImages[0]} alt={hotelName} />
                      </AddImgWrapp1>
                    )}
                    {hotelImages && hotelImages.length > 1 && (
                      <AddImgWrapp2 className="flexCenter" title="Hotel Image 2">
                        <img src={hotelImages[1]} alt={hotelName} />
                      </AddImgWrapp2>
                    )}
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #2c3539;
  font-weight: bold;
  padding: 10px;
  &:hover {
    color: black;
  }
`;

const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;

const AddRightInner = styled.div`
  width: 100%;
`;

const AddImgWrapp = styled.div`
  position: relative;
  width: 100%; /* Full width of the parent container */
  margin: 0 3%;
  img {
    width: 100%; /* Ensure the image takes the full width */
    height: auto;
    max-height: 500px; /* Set a maximum height to enforce landscape orientation */
    object-fit: cover; /* Crop the image to fit the container without distortion */
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out;
  }
  &:hover img {
    transform: scale(1.1);
  }
`;

const EnquireButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const EnquireButton = styled.button`
  background-color: #2c3539;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: black;
  }
`;

const AddImgWrapp1 = styled(AddImgWrapp)``;
const AddImgWrapp2 = styled(AddImgWrapp)``;
