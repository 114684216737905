import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../Sections/Footer";
import SafariDetailsHeader from "../Sections/SafariDetailsHeader"; // Adjusted the header component name

export default function SafariPage() {
  const { safariID } = useParams(); // Get the safari ID from the route params
  const navigate = useNavigate();
  const [safari, setSafari] = useState(null);
  const [expandedDay, setExpandedDay] = useState(null); // State to manage which day is expanded

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchSafariDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/safari/${safariID}`);
        setSafari(response.data);
      } catch (error) {
        console.error("Error fetching safari details:", error);
      }
    };

    fetchSafariDetails();
  }, [safariID]);

  if (!safari) {
    return <div>Loading...</div>; // Display a loading state while fetching safari data
  }

  const handleToggleDay = (dayIndex) => {
    setExpandedDay(expandedDay === dayIndex ? null : dayIndex);
  };

  const handleEnquire = () => {
    navigate('/booking-form'); // Replace with the actual enquiry page path
  };

  return (
    <>
      <SafariDetailsHeader
        safariTitle={safari.safariTitle}
        location={safari.location}
        safariImages={safari.safariImages.map(img => `${process.env.REACT_APP_API_BASE_URL}${img.safariImageUrl}`)}
      />
      <Wrapper id="aboutPage">
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40 extraBold">{safari.safariTitle}</h2>
                <p className="font12">Location: {safari.location}</p>
                <br />
                <p className="font12">{safari.safariDescription}</p>
                <br/>
                <h4 className="font15 semiBold">Duration</h4>
                <p className="font12">{safari.duration} days</p>
                <br />
                <h4 className="font15 semiBold">Itinerary</h4>
                <Itinerary>
                  {safari.safariItinerary.map((day, index) => (
                    <Day key={index}>
                      <DayHeader onClick={() => handleToggleDay(index)}>
                        <DayTitle>
                        {`${day.day}`}
                          <Arrow>{expandedDay === index ? "▲" : "▼"}</Arrow>
                        </DayTitle>
                      </DayHeader>
                      {expandedDay === index && (
                        <DayDetails>
                          <ul>
                            {day.activities.map((activity, activityIndex) => (
                              <React.Fragment key={activityIndex}>
                                <ActivityItem>{activity}</ActivityItem>
                                {activityIndex < day.activities.length - 1 && <Divider />}
                              </React.Fragment>
                            ))}
                          </ul>
                        </DayDetails>
                      )}
                    </Day>
                  ))}
                </Itinerary>
              </AddLeft>
              <AddRight>
                {/* Additional content can go here if needed */}
              </AddRight>
            </Advertising>
          </div>
        </div>
      </Wrapper>
      <br />
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;

const Itinerary = styled.div`
  margin-top: 20px;
`;

const Day = styled.div`
  margin-bottom: 20px;
`;

const DayHeader = styled.div`
  background-color: #f5f5f5;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
`;

const DayTitle = styled.h5`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
`;

const Arrow = styled.span`
  font-size: 18px;
`;

const DayDetails = styled.div`
  padding: 10px;
  border-top: none;
`;

const ActivityItem = styled.li`
  padding: 5px 0;
`;

const Divider = styled.hr`
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #e0e0e0;
  width: 100%;
`;

const EnquireButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const EnquireButton = styled.button`
  background-color: #2c3539;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: black;
  }
`;
