import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom"; 

// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/girrafe2.png";
import AddImage2 from "../../assets/img/ourstory2.jpeg";
import AddImage3 from "../../assets/img/lioness.jpeg";
import AddImage4 from "../../assets/img/wilderbeasts.jpeg";
import EasyBookingImage from "../../assets/img/offer-up.png";
import AffordableImage from "../../assets/img/business-and-finance.png";
import ConveninceImage from "../../assets/img/offer-up.png";
import SafetyImage from "../../assets/img/protection.png";

export default function Services() {

  const navigate = useNavigate(); 

  const handleLoadMore = () => {
    navigate('/hotels');
  }

  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Why Jokkajok</h1>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                image={EasyBookingImage} 
                title="Easy Booking"
                subtitle="Booking your dream safari has never been easier. 
                Our user-friendly online platform allows you to choose from a variety of packages and customize your itinerary in just a few clicks."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                image={AffordableImage} 
                title="Affordable"
                subtitle="Explore the wonders of Kenya without breaking the bank. 
                Our affordable Kenya safari packages offer exceptional value, combining quality experiences with budget-friendly options."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                image={ConveninceImage} 
                title="Convenience"
                subtitle="Experience the ultimate convenience with our all-inclusive Kenyan safari packages. 
                From seamless airport transfers to comfortable accommodations and guided tours, we take care of every detail."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox 
                image={SafetyImage} 
                title="Safety" 
                subtitle="Your safety is our top priority on every safari adventure. 
                Our experienced guides and well-maintained vehicles ensure a secure and enjoyable journey."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40 extraBold" id="destinations">Featured Destinations </h2>
                <p className="font12">
                Discover the Wonders of Kenya Safaris
                Embark on an unforgettable journey through the heart of Africa with our Kenya safaris. 
                From the vast plains of the Maasai Mara to the lush greenery of Tsavo National Park, our safaris offer an up-close encounter with the diverse wildlife and stunning landscapes that make Kenya a premier safari destination. 
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  <div style={{ width: "190px" }}>
                    <FullButton title="See all" action = {handleLoadMore} />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter" title="Ol Pejeta Sanctuary">
                      <img src={AddImage1} alt="Giraffe" />
                    </AddImgWrapp1>
                    <AddImgWrapp2 className="flexCenter" title="Tsavo">
                      <img src={AddImage2} alt="Wilderbeasts" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    {/* <AddImgWrapp3 className="flexCenter" title="Amboseli">
                      <img src={AddImage3} alt="Lioness" />
                    </AddImgWrapp3> */}
                    <AddImgWrapp4 className="flexCenter" title="Maasai Mara">
                      <img src={AddImage4} alt="Bird" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp = styled.div`
  position: relative;
  width: 50%;
  margin: 0 3%;
  img {
    width: 110%; /* Slightly increase the size of the images */
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out;
  }
  &:hover img {
    transform: scale(1.1);
  }
  &:hover::after {
    content: attr(title);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.40);
    color: white;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 1.2rem;
    text-align: center;
  }
`;
const AddImgWrapp1 = styled(AddImgWrapp)``;
const AddImgWrapp2 = styled(AddImgWrapp)`
 img {
   margin-left: auto; /* Move the fourth image to the right */
    width: 420px; /* Slightly increase the size of the image */
    height: 250px;

    @media (max-width: 500px) {
    width: 120%;
    height: 140px;
    }
  }
`;
const AddImgWrapp3 = styled(AddImgWrapp)``;
const AddImgWrapp4 = styled(AddImgWrapp)`
  margin-left: auto; /* Move the fourth image to the right */
  img {
    width: 110%; /* Slightly increase the size of the image */
    height: auto;
  }
`;
