import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Components
import ProjectBox from "../Elements/ProjectBox";
import ExploreKenyaHeader from "../Sections/ExploreKenyaHeader";
import Footer from "../Sections/Footer";

export default function ExploreKenya() {
  const navigate = useNavigate();
  const [safaris, setSafaris] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchSafaris = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/safari`);
        setSafaris(response.data);
      } catch (error) {
        console.error("Error fetching safaris:", error);
      }
    };

    fetchSafaris();
  }, []);

  const handleViewProduct = (safariID) => {
    navigate(`/safari/${safariID}`);
  };

  return (
    <>
      <ExploreKenyaHeader />
      <Wrapper id="projects">
        <div className="whiteBg">
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">Explore Kenya Safaris</h1>
            </HeaderInfo>
            <div className="row textCenter">
              {safaris.map((safari) => (
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" key={safari.safariID}>
                  <ProjectBox
                    img={
                      safari.safariImages && safari.safariImages.length > 0
                        ? `${process.env.REACT_APP_API_BASE_URL}${safari.safariImages[0].safariImageUrl}`
                        : null // fallback image can be added here
                    }
                    title={safari.safariTitle}
                    text={`${safari.location} - ${safari.duration} days`}
                    action={() => handleViewProduct(safari.safariID)}
                    imgStyle={{ width: '376px', height: '309px', objectFit: 'cover', objectPosition: 'center' }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Wrapper>
      <br />
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
