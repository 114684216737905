import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import jokkajokLogo from "../../assets/img/jokkajok.png";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="lightBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              <img src={jokkajokLogo} alt="Jokkajok Safaris Logo" style={{ marginTop: 20, width: 'auto', height: '110px' }} />
              <h1 className="font15 extraBold darkColor" style={{ marginLeft: "45px", fontSize:"25px" }}>
                Jokkajok Safaris
              </h1>
            </Link>
            <StyleP className="darkColor font13" style={{ fontSize:"20px" }}>
              © {getCurrentYear()} - <span className="greenColor font13" style={{ fontSize:"20px" }}>Jokkajok Safaris</span> All Rights Reserved
            </StyleP>
            <Link className="darkColor animate pointer font13" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </InnerWrapper>
          <ContactInfo className="flexSpaceCenter">
            <p style={{ fontSize:"20px" }}>Nairobi, Kenya</p>
            <p style={{ fontSize:"20px" }}>info@jokkajoksafaris.com</p>
            <p style={{ fontSize:"20px" }}>+254 716 042178</p>
          </ContactInfo>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
const ContactInfo = styled.div`
  text-align: center;
  margin-top: 20px;
  p {
    margin: 5px 0;
    font-size: 16px;
  }
  @media (max-width: 550px) {
    flex-direction: column;
    p {
      font-size: 14px;
    }
  }
`;
