import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Components
import ProjectBox from "../Elements/ProjectBox";
import HotelsHeader from "../Sections/HotelsHeader";
import Footer from "../Sections/Footer";

export default function Hotels() {
  const navigate = useNavigate();
  const [hotels, setHotels] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchHotels = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/hotel`);
        setHotels(response.data);
      } catch (error) {
        console.error("Error fetching hotels:", error);
      }
    };

    fetchHotels();
  }, []);

  const handleViewHotel = (hotelID) => {
    navigate(`/product-page/${hotelID}`);
  };

  return (
    <>
      <HotelsHeader />
      <Wrapper id="projects">
        <div className="whiteBg">
          <div className="container">
            {/* <HeaderInfo>
              <h1 className="font40 extraBold">Destinations and Hotels</h1>
            </HeaderInfo> */}
            <div className="row textCenter">
              {hotels.map((hotel) => (
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" key={hotel.id}>
                  <ProjectBox
                    img={
                      hotel.hotelImages && hotel.hotelImages.length > 0
                        ? `${process.env.REACT_APP_API_BASE_URL}${hotel.hotelImages[0].hotelImageUrl}`
                        : null // fallback image can be added here
                    }
                    title={hotel.name}
                    text={`${hotel.location}`}
                    action={() => handleViewHotel(hotel.id)}
                    imgStyle={{ width: '376px', height: '309px', objectFit: 'cover', objectPosition: 'center' }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Wrapper>
      <br />
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
