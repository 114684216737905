import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
// Assets
import ContactImg1 from "../../assets/img/bird.jpeg";
import ContactImg2 from "../../assets/img/lioness.jpeg";
import ContactImg3 from "../../assets/img/leopard_jumping.jpeg";
import FullButton from "../Buttons/FullButton";

export default function Contact() {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    message: '',
  });

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const messageData = {
        fname: formData.fname,
        lname: formData.lname,
        email: formData.email,
        messageEnquiry: formData.message,
        createdAt: new Date().toISOString(),
      };

      await axios.post(`${apiUrl}/api/v1/message`, messageData);
      alert('Message sent successfully!');
      
      // Reset the form
      setFormData({
        fname: '',
        lname: '',
        email: '',
        message: '',
      });
    } catch (error) {
      console.error('Error sending message:', error);
      alert('There was an error sending your message. Please try again.');
    }
  };

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form onSubmit={handleSubmit}>
                <label className="font13">First name:</label>
                <input 
                  type="text" 
                  id="fname" 
                  name="fname" 
                  className="font20 extraBold" 
                  value={formData.fname} 
                  onChange={handleInputChange} 
                  required 
                />
                <label className="font13">Last name:</label>
                <input 
                  type="text" 
                  id="lname" 
                  name="lname" 
                  className="font20 extraBold" 
                  value={formData.lname} 
                  onChange={handleInputChange} 
                  required 
                />
                <label className="font13">Email:</label>
                <input 
                  type="email" 
                  id="email" 
                  name="email" 
                  className="font20 extraBold" 
                  value={formData.email} 
                  onChange={handleInputChange} 
                  required 
                />
                <label className="font13">Message:</label>
                <textarea 
                  rows="4" 
                  cols="50" 
                  id="message" 
                  name="message" 
                  className="font20 extraBold" 
                  value={formData.message} 
                  onChange={handleInputChange} 
                  required 
                />
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton type="submit" title="Send Message" className="pointer animate radius8" />
                  </div>
                </ButtonsRow>


              </Form>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <ContactImgBox>
                    <img src={ContactImg3} alt="office" className="radius6" />
                  </ContactImgBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
 border: 1px solid ${(props) => (props.border ? "#707070" : "#7620ff")};
  background-color: ${(props) => (props.border ? "transparent" : "#315527")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#FFA500")};
    border: 1px solid #7620ff;
    color: ${(props) => (props.border ? "#7620ff" : "#fff")};
  }
`;
const ContactImgBox = styled.div`
  max-width: 400px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
  
  img {
    width: 100%; /* Make the image fill the width of the container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure the image covers the container */
    border-radius: 6px; /* Maintain the border-radius */
  }
`;
const SumbitWrapper = styled.div`
  max-width: 220px;
  margin: 0 auto;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
