import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
// Assets
import AddImage2 from "../../assets/img/sunset_girrafe.jpeg";
import AboutHeader from "../Sections/AboutHeader";
import Footer from "../Sections/Footer";
import { ArrowBack } from '@mui/icons-material'; // Importing MUI icon


export default function About() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  return (
    <>
       <BackButtonWrapper onClick={handleBack}>
        <ArrowBack /> {/* Back arrow icon */}
        <span>Back</span>
      </BackButtonWrapper>
      <AboutHeader />
      <Wrapper id="aboutPage">
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <AddLeftInner>
                  <ImgWrapper className="flexCenter">
                    <img className="radius8" src={AddImage2} alt="add" />
                  </ImgWrapper>
                </AddLeftInner>
              </AddLeft>
              <AddRight>
                <p className="font12">
                  Founded in 2020, Jokkajok Safaris is more than just a travel agency; it's a testament to the passion for exploration and the beauty of Kenya's breathtaking landscapes. 
                  As a family-run business based in Kenya, we take pride in offering unique and unforgettable experiences that showcase the unparalleled richness of East and Central Africa. 
                  From the majestic savannahs of the Maasai Mara to the pristine shores of the Indian Ocean, Jokkajok Safaris specializes in crafting tailor-made adventures that immerse you in the wonders of nature.
                </p>
                <br />
                <p className="font12">
                  With a deep-rooted commitment to sustainability and responsible tourism, we strive to leave a positive impact on the communities and environments we visit.
                  Our dedicated team of experts ensures that every journey with us is not only exhilarating but also ethically sound.
                  Whether you're seeking an adrenaline-fueled safari, a cultural odyssey, or a tranquil retreat amidst nature, Jokkajok Safaris has something for every discerning traveler. 
                  Come join us as we embark on a journey of discovery, where every moment is an opportunity to create lifelong memories.
                  Experience the magic of Africa with Jokkajok Safaris - your gateway to adventure, authenticity, and unparalleled hospitality.
                </p>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const BackButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  margin: 20px;
  padding: 10px 20px;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: 400px;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #2c3539;
  font-weight: bold;
  padding: 10px;
  &:hover {
    color: black;
  }
`;