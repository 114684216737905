import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/blog`);
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };


    fetchBlogs();
  }, []);

  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Blog Stories</h1>
          </HeaderInfo>
          <div className="row textCenter">
            {blogs.map((blog) => (
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" key={blog.blogId}>

                <BlogBox
                  title={blog.blogTitle}
                  text={blog.blogContent.substring(0, 100) + "..."} 
                  tag={blog.blogTags.map(tag => tag.tag).join(" ")} // Correctly format tags
                  author={`${blog.author}, ${new Date(blog.createdAt).toLocaleDateString()}`}
                  action={() => navigate(`/blog/${blog.blogId}`)} // Navigate to blog details page
                />
              </div>
            ))}
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => navigate('/all-blogs')} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{ padding: '50px 0' }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What They Say?</h1>
            <p className="font13">
            At Jokkajok Safaris, nothing brings us more joy than hearing about the unforgettable experiences our guests have had.
              <br />
              Here are a few words from those who’ve journeyed with us and discovered the magic of Africa.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
