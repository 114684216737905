import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';

const SafariList = () => {
  const [safaris, setSafaris] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchSafaris = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/safari`);
        setSafaris(response.data);
      } catch (error) {
        console.error('Error fetching safaris:', error);
      }
    };

    fetchSafaris();
  }, [apiUrl]);

  const columns = [
    { field: 'safariID', headerName: 'Safari ID', width: 100 },
    { field: 'safariTitle', headerName: 'Safari Title', width: 300 },
    { field: 'location', headerName: 'Location', width: 250 },
    { field: 'duration', headerName: 'Duration (Days)', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleEdit(params.row.safariID)}
            aria-label="edit"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleDelete(params.row.safariID)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleEdit = (safariID) => {
    // Navigate to the edit page with the safariID
    navigate(`/edit-safari/${safariID}`);
  };

  const handleDelete = async (safariID) => {
    try {
      await axios.delete(`${apiUrl}/api/v1/safari/${safariID}`);
      setSafaris(safaris.filter((safari) => safari.safariID !== safariID));
    } catch (error) {
      console.error('Error deleting safari:', error);
    }
  };

  return (
    <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
      <h2>Current Safari Listings</h2>
      <DataGrid
        rows={safaris}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        getRowId={(row) => row.safariID}
      />
    </div>
  );
};

export default SafariList;
