import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField, Button, Container, Typography, Box, Chip, Input, FormControl, FormHelperText
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

const EditBlogPage = () => {
  const { blogId } = useParams(); // Get the blog ID from the route parameters
  const navigate = useNavigate();
  const [blog, setBlog] = useState({
    blogTitle: '',
    blogContent: '',
    author: '',
    blogTags: [],
    blogImage: '',
    createdAt: '',
  });
  const [newTag, setNewTag] = useState(''); // For adding new tags
  const [imagePreview, setImagePreview] = useState(''); // Preview the current image

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/blog/${blogId}`);
        const fetchedBlog = response.data;
        setBlog({
          ...fetchedBlog,
          blogTags: fetchedBlog.blogTags.map(tag => tag.tag), // Flatten the tags array
        });
        setImagePreview(fetchedBlog.blogImage ? `${apiUrl}${fetchedBlog.blogImage}` : '');
      } catch (error) {
        console.error('Error fetching blog:', error);
      }
    };

    fetchBlog();
  }, [blogId, apiUrl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlog({ ...blog, [name]: value });
  };

  const handleTagsChange = (e) => {
    if (e.key === 'Enter' && newTag.trim() !== '') {
      e.preventDefault(); // Prevent form submission on Enter key press
      setBlog({ ...blog, blogTags: [...blog.blogTags, newTag.trim()] });
      setNewTag('');
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setBlog({ ...blog, blogTags: blog.blogTags.filter((tag) => tag !== tagToDelete) });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBlog({ ...blog, blogImage: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('blogTitle', blog.blogTitle);
    formData.append('blogContent', blog.blogContent);
    formData.append('author', blog.author);
    formData.append('createdAt', blog.createdAt);
    blog.blogTags.forEach((tag) => formData.append('blogTags', tag));
    if (blog.blogImage instanceof File) {
      formData.append('blogImage', blog.blogImage);
    }

    try {
      await axios.put(`${apiUrl}/api/v1/blog/${blogId}`, formData);
      alert('Blog updated successfully!');
      navigate('/blogList');
    } catch (error) {
      console.error('Error updating blog:', error);
      alert('There was an error updating your blog. Please try again.');
    }
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Edit Blog
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            label="Blog Title"
            name="blogTitle"
            variant="outlined"
            fullWidth
            value={blog.blogTitle}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Content"
            name="blogContent"
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            value={blog.blogContent}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Author"
            name="author"
            variant="outlined"
            fullWidth
            value={blog.author}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Tags</Typography>
          <Box display="flex" flexWrap="wrap" mb={2}>
            {blog.blogTags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleDeleteTag(tag)}
                style={{ margin: '5px' }}
              />
            ))}
          </Box>
          <FormControl fullWidth variant="outlined">
            <Input
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyDown={handleTagsChange}
              placeholder="Add a tag and press Enter"
            />
            <FormHelperText>Press Enter to add a tag</FormHelperText>
          </FormControl>
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Upload Image</Typography>
          {imagePreview && (
            <Box mb={2}>
              <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', borderRadius: '8px' }} />
            </Box>
          )}
          <input type="file" onChange={handleFileChange} />
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Update Blog
        </Button>
      </form>
    </Container>
  );
};

export default EditBlogPage;
