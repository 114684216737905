import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Container, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/blog`);
        setBlogs(response.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, [apiUrl]);

  const handleEdit = (blogId) => {
    navigate(`/editBlog/${blogId}`);
  };

  const handleDelete = async (blogId) => {
    try {
      await axios.delete(`${apiUrl}/api/v1/blog/${blogId}`);
      setBlogs(blogs.filter((blog) => blog.blogId !== blogId));
    } catch (error) {
      console.error('Error deleting blog:', error);
    }
  };

  const columns = [
    { field: 'blogId', headerName: 'ID', width: 70 },
    { field: 'blogTitle', headerName: 'Title', width: 200 },
    { field: 'author', headerName: 'Author', width: 150 },
    { field: 'createdAt', headerName: 'Created At', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row.blogId)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.blogId)} color="secondary">
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Container style={{ marginTop: '20px' }}>
      <h2>View Blogs</h2>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={blogs}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          disableSelectionOnClick
          getRowId={(row) => row.blogId}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '20px' }}
        onClick={() => navigate('/createNewBlog')}
      >
        Add New Blog
      </Button>
    </Container>
  );
};

export default BlogList;
